/**
 * Config
 * -------------------------------------------------------------------------------------
 * ! IMPORTANT: Make sure you clear the browser local storage In order to see the config changes in the template.
 * ! To clear local storage: (https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/).
 */

'use strict';

// JS global variables
export const config = {
  app: {
    website: "https://panel.hopehomecalcutta.org",
    server: {
      url: 'https://panel.hopehomecalcutta.org/server',
      token: 'Tic1GU3MEMJLtDckvfkhHNBmZzJqS9DzTFe9aufDmX3LTBqyL'
    },
    access: [
      { id: 1, type: "Events", create: true, modify: false, del: false, pageNew: '/event/add-new.html', pageView: '/event/view-list.html', iconClass: 'bx bx-calendar-event'},
      { id: 2, type: "News", create: true, modify: false, del: false, pageNew: '/news/add-new.html', pageView: '/news/view-list.html', iconClass: 'bx bx-dock-top'},
      { id: 3, type: "Project", create: true, modify: false, del: false, pageNew: '/project/add-new.html', pageView: '/project/view-list.html', iconClass: 'bx bx-money-withdraw'},
      { id: 4, type: "Gallery", create: true, modify: false, del: false, pageNew: '/gallery/add-new.html', pageView: '/gallery/view-list.html', iconClass: 'bx bx-image-alt'},
      { id: 5, type: "Hope_connect", create: true, modify: false, del: false, pageNew: '/connect/add-new.html', pageView: '/connect/view-list.html', iconClass: 'bx bx-book-reader'},
      { id: 6, type: "Contacts", create: 0, modify: false, del: false, pageNew: '', pageView: '/forms/contact-us.html', iconClass: 'bx bx-message-rounded-dots'},
      { id: 7, type: "Volunteers", create: 0, modify: false, del: false, pageNew: '', pageView: '/forms/volunteers.html', iconClass: 'bx bxs-face'},
    ],
    roles: [
      { id: 1, type: "Director", maxLimit: 1 },
      { id: 2, type: "Manager", maxLimit: 2 },
      { id: 3, type: "Staff", maxLimit: 25 },
    ],
    enum:{
      localStorage:{
        profile: "as_admin_profile",
        users: "as_admin_users"
      }
    }
  },
  colors: {
    primary: '#24CD7E',
    secondary: '#8592a3',
    success: '#71dd37',
    info: '#03c3ec',
    warning: '#ffab00',
    danger: '#ff3e1d',
    dark: '#233446',
    black: '#000',
    white: '#fff',
    body: '#f4f5fb',
    headingColor: '#566a7f',
    axisColor: '#a1acb8',
    borderColor: '#eceef1'
  },
};
